import React from 'react'

export default function Services() {
    return <>
        <div className='bgsection'>
            <div className='container pt-5'>
                <div className='row'>
                    <div className='col-lg-3 col-md-6 col-sm-12 mt-3'>
                        <div className=' feat-img mx-auto'>
                            <img src='https://eadentalclinic.com/wp-content/uploads/2024/06/feature1.png' className='p-2 img-fluid' />
                        </div>
                        <div className='d-center flex-column'>
                        <h5 className='text-dark mt-3 font-17'>Professional Staff</h5>
                        <p className='text-dark'>طاقم طبي محترف ومتعاون</p>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-6 col-sm-12 mt-3'>
                        <div className=' feat-img mx-auto'>
                            <img src='https://eadentalclinic.com/wp-content/uploads/2024/06/feature3.png' className='p-2 img-fluid' />
                        </div>
                        <div className='d-center flex-column'>
                        <h5 className='text-dark mt-3 font-17'>Comfortable Office</h5>
                        <p className='text-dark'> عيادة مريحة مهيأه بالكامل</p>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-6 col-sm-12 mt-3'>
                        <div className=' feat-img mx-auto'>
                            <img src='https://eadentalclinic.com/wp-content/uploads/2024/06/feature2.png' className='p-2 img-fluid' />
                        </div>
                        <div className='d-center flex-column'>
                        <h5 className='text-dark mt-3 font-17'>High Quality Equipments</h5>
                        <p className='text-dark'>  أدوات حديثة عالية الجودة  </p>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-6 col-sm-12 mt-3'>
                        <div className=' feat-img mx-auto'>
                            <img src='https://eadentalclinic.com/wp-content/uploads/2024/06/feature1.png' className='p-2 img-fluid' />
                        </div>
                        <div className='d-center flex-column'>
                        <h5 className='text-dark mt-3 font-17'>Advanced Dentistry</h5>
                        <p className='text-dark'>     خدمة طبية متطورة  </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}
