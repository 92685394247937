import React from 'react'

export default function ImgServices() {
  return <>
    <div className='bgsection ' id='imgservise'>
        <div className=''>
            <div className='container overflow-auto '>
                <div className='row mt-5 '>
                    <div className='col-lg-4 col-md-6 col-sm-12 mt-3'>
                        <div>
                            <img src='https://eadentalclinic.com/wp-content/uploads/2024/06/clinic1.jpg' className='img-fluid'/>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-6 col-sm-12 mt-3'>
                        <div>
                            <img src='https://eadentalclinic.com/wp-content/uploads/2024/06/clinic3.jpg' className='img-fluid'/>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-6 col-sm-12 mt-3'>
                        <div>
                            <img src='https://eadentalclinic.com/wp-content/uploads/2024/06/clinic5.jpg' className='img-fluid'/>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-6 col-sm-12 mt-3'>
                        <div>
                            <img src='https://eadentalclinic.com/wp-content/uploads/2024/06/clinic5.jpg' className='img-fluid'/>
                        </div>
                    </div>
               
                    <div className='col-lg-4 col-md-6 col-sm-12 mt-3'>
                        <div>
                            <img src='https://eadentalclinic.com/wp-content/uploads/2024/06/clinic4.jpg' className='img-fluid'/>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-6 col-sm-12 mt-3 mb-5'>
                        <div>
                            <img src='https://eadentalclinic.com/wp-content/uploads/2024/06/clinic2.jpg' className='img-fluid'/>
                        </div>
                    </div>



                </div>
            </div>
        </div>
    </div>
  </>
}
