import React from 'react'

export default function BookAppointment() {
    return <>

        <div className='BookAppointment overflow-auto'>
            <div className='container mt-5 '>
                <div className='row'>
                    <div className='col-lg-8 col-md-12'>
                        <div className='bg-white'>
                            <div className='text-center pt-4 titleAppointment'>
                                <h5 className='text-color fw-bold '>Book Your Appointment</h5>
                                <span className='mx-auto'></span>
                            </div>

                            <div className='container mt-4'>
                                <div className='row'>

                                    <div className='col-lg-6 col-md-12'>
                                        <form>
                                            <input className='form-control formAppointment mt-4' type="text" placeholder='Full Name' />
                                            <input className='form-control formAppointment mt-3' type="email" placeholder='E-mail' />
                                            <input className='form-control formAppointment mt-3' type="number" placeholder='Phone Number' />

                                            <select class="form-select mt-3 formAppointment" aria-label="Default select example">
                                                <option selected>Maxillofacial surgery</option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </select>
                                            <input className='form-control formAppointment mt-3' type="date" placeholder='Phone Number' />



                                            <div className='mt-2 pb-4'>

                                                <button className='btn btn-color text-white  w-100'>Order An Appointment</button>


                                            </div>
                                        </form>
                                    </div>
                                    <div className='col-lg-6 col-md-12 '>

                                        <img className='img-fluid' src='https://eadentalclinic.com/wp-content/uploads/2024/06/img-booking.png' />

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className='col-lg-4 col-md-12 mt-5'>

                        <div className='btn-color'>
                            <h5 className='pt-4 text-center text-white'>           Working Hours </h5>
                            <p className='text-whiute mx-2'>Check out EA clinic’s Office hours to plan your visit.</p>


                            <div className='d-flex justify-content-between mx-3 mt-2'>
                                <div>
                                    <a href="https://wa.me/01126303082?text=I'd%20like%20to%20make%20an%20appointment" class="btn btn-color" target="_blank">
                                    
                                    <button className='btn btn-dark btn-sm'>Book</button>
                                    </a>
                                </div>

                                <div>
                                    <p>
                                        4PM-10PM</p>
                                </div>
                                <div>
                                    <h5>Saturday</h5>
                                </div>

                            </div>
                            <div className='d-flex justify-content-between mx-3 mt-2'>
                                <div>
                                <a href="https://wa.me/01126303082?text=I'd%20like%20to%20make%20an%20appointment" class="btn btn-color" target="_blank">
                                    
                                    <button className='btn btn-dark btn-sm'>Book</button>
                                    </a>
                                </div>

                                <div>
                                    <p>
                                        4PM-10PM</p>
                                </div>
                                <div>
                                    <h5>Sunday</h5>
                                </div>

                            </div>
                            <div className='d-flex justify-content-between mx-3 mt-2'>
                                <div>
                                      <a href="https://wa.me/01126303082?text=I'd%20like%20to%20make%20an%20appointment" class="btn btn-color" target="_blank">
                                    
                                    <button className='btn btn-dark btn-sm'>Book</button>
                                    </a>
                                </div>

                                <div>
                                    <p>
                                        4PM-10PM</p>
                                </div>
                                <div>
                                    <h5>Monday</h5>
                                </div>

                            </div>
                            <div className='d-flex justify-content-between mx-3 mt-2'>
                                <div>
                                    <button className='btn btn-dark btn-sm'>Book</button>
                                </div>

                                <div>
                                    <p>
                                        4PM-10PM</p>
                                </div>
                                <div>
                                    <h5>Tuesday</h5>
                                </div>

                            </div>
                            <div className='d-flex justify-content-between mx-3 mt-2'>
                                <div>
                                    <button className='btn btn-dark btn-sm'>Book</button>
                                </div>

                                <div>
                                    <p>
                                        4PM-10PM</p>
                                </div>
                                <div>
                                    <h5>Wednesday</h5>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    </>
}
