import React from 'react'

export default function ImgDoctors() {
    return <>
        <div className='container mt-5'>
            <div className='row pb-5'>
                <div className='col-lg-3 col-md-6 col-sm-12'>
                    <div className='w-100'>
                        <img src='https://eadentalclinic.com/wp-content/uploads/2024/07/DSC_3126-1.jpg' className='img-fluid w-100 h-card' />
                    </div>
                </div>
                <div className='col-lg-3 col-md-6 col-sm-12'>
                    <div className='w-100'>
                        <img src='https://eadentalclinic.com/wp-content/uploads/2024/07/DSC_3094-1.jpg' className='img-fluid w-100 h-card' />
                    </div>
                </div>
                <div className='col-lg-3 col-md-6 col-sm-12'>
                    <div className='w-100'>
                        <img src='https://eadentalclinic.com/wp-content/uploads/2024/07/IMG-20240307-WA0001.jpg' className='img-fluid w-100 h-card' />
                    </div>
                </div>
                <div className='col-lg-3 col-md-6 col-sm-12'>
                    <div className='w-100'>
                        <img src='https://eadentalclinic.com/wp-content/uploads/2024/07/DSC_3245-1.jpg' className='img-fluid w-100 h-card' />
                    </div>
                </div>
            </div>
        </div>
    </>
}
