import React from 'react';
import Slider from 'react-slick';

export default function Testmonials() {

    var settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div className='mt-5'>
            <div className='text-center mb-4'>
                <h5 className='text-color'>Our Clients Reviews</h5>
            </div>

            <div className='container'>
                <div className='row'>
                    <Slider {...settings}>
                        <div className='mx-2'>
                            <div className='sliderboxshado p-4'>
                                <div className='text-black'>
                                    بجد انا عايزة اشكر دكتور عمرو جداااااااااا علي الاهتمام والضمير لان فعلا مش بيشتغل وخلاص لا هو بيوصل لاعلي حاجة واحسن حاجة والادوات كمان من افضل الادوات المستخدمة انا فعلا مبسوطة بالتعامل معاهم وهفضل معاهم ان شاء الله لغاية ما اوصل لضحكة انغام 😅😅
                                </div>
                                <div className='mt-4'>
                                    <div className='d-flex align-items-center'>
                                    <div className='img-icone ml-2'>
                                            <img className='img-fluid' src='https://eadentalclinic.com/wp-content/uploads/2024/06/0d64989794b1a4c9d89bff571d3d5842.jpg' alt="testimonial" />
                                        </div>
                                        <div className='mt-3'>
                                            <div>
                                                <h6 className='fw-bold text-dark'>Rana Mohammed</h6>
                                            </div>
                                            <div>
                                                <i className="fa-solid fa-star text-yellow fa-1x"></i>
                                                <i className="fa-solid fa-star text-yellow fa-1x"></i>
                                                <i className="fa-solid fa-star text-yellow fa-1x"></i>
                                                <i className="fa-solid fa-star text-yellow fa-1x"></i>
                                            </div>
                                        </div>
                                      
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Add more slides here if needed */}
                    </Slider>
                </div>
            </div>
        </div>
    );
}
