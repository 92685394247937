import React from 'react';
import { useTranslation } from 'react-i18next';

export default function About() {
    const { t } = useTranslation();
    let languag = localStorage.getItem("language")

    return (
        <>
            <div className='bgsection' id='about'>
                <div className='container pt-5'>
                    <div className='row'>
                        <div className='col-lg-5 col-md-12'>
                            <div className='w-100'>
                                <div className='wp-block-tornado-about-section alignfull about-us' id='AboutUs'>
                                    <div>
                                        <div>
                                            <div>
                                           
                                            </div>
                                            <div className='col-lg- col-sm-12'>
                                                <div className='about-video'>
                                                    <video className='w-100' controls>
                                                        {/* <source src='https://eadentalclinic.com/wp-content/uploads/2024/06/about.mp4' type='video/mp4' /> */}
                                                        <source src='https://eadentalclinic.com/wp-content/uploads/2024/06/about.mp4' type='video/ogg' />
                                                        Your browser does not support the video tag.
                                                    </video>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-7 col-md-12'>
                            <div className={languag == "en"?"text-start":"text-end"}>
                                <p className='text-dark'> {t("welcomedent")} </p>
                                <h2 className='text-dark mt-4'>Your Trust is your Value</h2>
                                <p className='text-dark mt-4'>
                                    With Experienced Specialists with vast experience, we guarantee not only the best Treatment, but also, your Trust
                                </p>
                                <a href="https://wa.me/01126303082?text=I'd%20like%20to%20make%20an%20appointment" class="btn btn-color" target="_blank">
                                    <i class="fab fa-whatsapp"></i> {t("appointmentmake")}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
