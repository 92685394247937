import React, { useState, useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import UserContextprovider from './Components/Context/Context';
import routers from './Components/Routes/routes';
import { Toaster } from 'react-hot-toast';
import { generateToken, messaging } from './firebase';
import { onMessage } from 'firebase/messaging';
import FirebaseContextProvider from './Components/Firbase/ContextFirbase';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import i18n from './i18n';
import Loading from './Components/Fixed/Loading';
// import Loading from './Components/Loading/Loading'; // تأكد من مسار الاستيراد الصحيح

function App() {
  const [loading, setLoading] = useState(true);
  const [language, setLanguage] = useState(() => localStorage.getItem('language') || i18n.language || 'en');
  const [mymode, setMymode] = useState(() => localStorage.getItem('theme') || 'light');

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  useEffect(() => {
    generateToken();
    onMessage(messaging, (payload) => {
      console.log('Message received: ', payload);
    });
    // Simulate loading delay
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); // تغيير المدة حسب احتياجاتك

    return () => clearTimeout(timer);
  }, []);

  const darkTheme = createTheme({
    palette: {
      mode: mymode,
    },
  });

  const toggleTheme = () => {
    setMymode(prevMode => {
      const newMode = prevMode === 'light' ? 'dark' : 'light';
      localStorage.setItem('theme', newMode);
      return newMode;
    });
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      {loading && <Loading />}
      <FirebaseContextProvider>
        <UserContextprovider>
          <RouterProvider router={routers} />
          <Toaster />
        </UserContextprovider>
      </FirebaseContextProvider>
    </ThemeProvider>
  );
}

export default App;
