import React from 'react'

export default function OurServices() {

    return <>
        <div className='bgsection' id='Services'>
            <div className=''>
                <div className='text-center  '>
                    <p className=' text-color fw-bold pt-3'>Our Services</p>
                    <h2 className='text-dark p-0 m-0'>A Perfect Smile Guaranteed</h2>
                </div>
                <div className='container mt-5'>
                    <div className='row'>
                        <div className='col-lg-4 col-md-12'>
                            <div className='serv service-right mt-5'>
                                <div className='serv-title text-dark w-70 '>
                                    <h4 className='text-center'>Maxillofacial surgery</h4>
                                    <p className='text-center'>( جراحة الوجه و الفكين )</p>
                                </div>
                                <div className='servimg d-center w-25 '>
                                <img src='https://png.pngtree.com/png-vector/20230903/ourmid/pngtree-kiss-lips-color-png-image_9952429.png' className='d-center w-100' />
                          
                                
                                {/* </p> */}
                                </div>
                            </div>
                            <div className='serv service-right mt-5 w-100'>
                                <div className='serv-title text-dark w-70 text-start '>
                                    <h4 className='mx-4'>
                                        Endo Treatment</h4>
                                    <p className='text-center'>( حشوات العصب )</p>
                                </div>
                                <div className='servimg d-center  w-25'>
                                    <img src='https://eadentalclinic.com/wp-content/uploads/2024/06/service2.png' className='d-center' />
                                </div>

                            </div>
                            <div className='serv service-right mt-5 w-100 '>
                                <div className='serv-title text-dark w-70 text-start '>
                                    <h4 className='mx-4'>
                                        Oral Care</h4>
                                    <p className='mx-4'>(    العنايه بالفم و اللثه )</p>
                                </div>
                                <div className='servimg d-center  w-25'>
                                    <img src='https://eadentalclinic.com/wp-content/uploads/2024/06/service3.png' className='d-center' />
                                </div>

                            </div>
                            <div className='serv service-right mt-5'>
                                <div className='serv-title text-dark w-70 '>
                                    <h4 className='text-center'>Maxillofacial surgery</h4>
                                    <p className='text-center'>( جراحة الوجه و الفكين )</p>
                                </div>
                                <div className='servimg d-center w-25'>
                                    <img src='https://eadentalclinic.com/wp-content/uploads/2024/06/service4.png' className='d-center' />
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-12'>
                            <div className='service-big-img'>
                                <img src='https://eadentalclinic.com/wp-content/uploads/2024/06/tes-services.png'/>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-12'>
                            <div className='serv service-right mt-5'>
                                <div className='serv-title text-dark w-70 '>
                                    <h4 className='text-center'>Maxillofacial surgery</h4>
                                    <p className='text-center'>( جراحة الوجه و الفكين )</p>
                                </div>
                                <div className='servimg d-center w-25'>
                                    <img src='https://eadentalclinic.com/wp-content/uploads/2024/06/service5.png' className='d-center' />
                                </div>
                            </div>
                            <div className='serv service-right mt-5 w-100'>
                                <div className='serv-title text-dark w-70 text-start '>
                                    <h4 className='mx-4'>
                                        Endo Treatment</h4>
                                    <p className='text-center'>( حشوات العصب )</p>
                                </div>
                                <div className='servimg d-center  w-25'>
                                    <img src='https://eadentalclinic.com/wp-content/uploads/2024/06/service-6.png' className='d-center' />
                                </div>

                            </div>
                            <div className='serv service-right mt-5 w-100 '>
                                <div className='serv-title text-dark w-70 text-start '>
                                    <h4 className='mx-4'>
                                        Oral Care</h4>
                                    <p className='mx-4'>(    العنايه بالفم و اللثه )</p>
                                </div>
                                <div className='servimg d-center  w-25'>
                                    <img src='https://eadentalclinic.com/wp-content/uploads/2024/06/service8.png' className='d-center' />
                                </div>

                            </div>
                            <div className='serv service-right mt-5'>
                                <div className='serv-title text-dark w-70 '>
                                    <h4 className='text-center'>Maxillofacial surgery</h4>
                                    <p className='text-center'>( جراحة الوجه و الفكين )</p>
                                </div>
                                <div className='servimg d-center w-25'>
                                    <img src='https://eadentalclinic.com/wp-content/uploads/2024/06/service5.png' className='d-center' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </>
}
