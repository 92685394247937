import React from 'react';
import { useTranslation } from 'react-i18next';

export default function Slider() {
  const { t } = useTranslation();
  let languag = localStorage.getItem("language")

  return (
    <div className='container-fluid2'>

      <div id="carouselExample" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-inner">
          <div class="carousel-item active">

            <div className='positionhover position-absolute'>

              <div className='positionhover'></div>
            </div>
            <img src="https://eadentalclinic.com/wp-content/uploads/2024/06/slider-home1-001.jpg" class="d-block w-100" alt="..." />



            <div class={languag == "en"?"carousel-caption d-none d-md-block text-start":"carousel-caption d-none d-md-block text-end"}>
              <p className=''>EA Elegant Art</p>
              <h2 className='text-dark '> {t("dentalservise")}  <br /> <span className='mt-2 d-block text-decoration-line-underline text-color'> {t("services")}  </span> </h2>
              <a href="#" class="btn btn-color"> {t("appointmentmake")} </a>
            </div>
          </div>
          <div class="carousel-item ">

            <div className='positionhover position-absolute'>

              <div className='positionhover'></div>
            </div>
            <img src="https://eadentalclinic.com/wp-content/uploads/2024/06/slider-home1-001.jpg" class="d-block w-100" alt="..." />



            <div class={languag == "en"?"carousel-caption d-none d-md-block text-start":"carousel-caption d-none d-md-block text-end"}>
              <p className=''>EA Elegant Art</p>
              <h2 className='text-dark '> {t("dentalservise")}  <br /> <span className='mt-2 d-block text-decoration-line-underline text-color'> {t("services")}  </span> </h2>
              <a href="#" class="btn btn-color"> {t("appointmentmake")} </a>
            </div>
          </div>
          {/* <div class="carousel-item ">

            <div className='positionhover position-absolute'>

              <div className='positionhover'></div>
            </div>
            <img src="https://eadentalclinic.com/wp-content/uploads/2024/06/slider-home1-001.jpg" class="d-block w-100" alt="..." />



            <div class="carousel-caption d-none d-md-block text-start">
        <p>EA Elegant Art</p>
        <h2 className='text-dark'> {t("dentalservise")}  <br/> <span className='mt-2 d-block text-decoration-line-underline text-color'> {t("services")}  </span> </h2>
        <a href="#" class="btn btn-color"> {t("appointmentmake")} </a>
      </div>
          </div> */}

        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>

    </div>
  );
}
