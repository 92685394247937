// src/Components/Routes/routes.js

import { createBrowserRouter } from 'react-router-dom';
import Layout from '../Layout/Layout';
import Home from '../Home/Home';
import Dashboard from '../Dashboard/Dashboard';
import HomeDashboard from '../Dashboard/Home';
import Login from '../Auth/Login';
import NotFound from '../NotFound/NotFound';
import LoginDashboard from "../Dashboard/Auth/Login/Login";
import MyDataTable from '../DataTable/DataTable';
import Slider from '../Dashboard/Slider/Slider';
import About from '../Dashboard/About/About';
import Reviews from '../Reviews/Reviews';
import Rejster from '../Auth/Rejster';
import FAQ from '../FAQ/FAQ';
import Detailseadentalclinic from '../Detailseadentalclinic/Detailseadentalclinic';
import Info from '../About/Info';

const routers = createBrowserRouter([
  {  
    path: "", 
    element: <Layout/>,  // General layout for non-dashboard pages
    children: [
      { path: "", element: <Home/> },
      { path: "Login", element: <Login/> },
      { path: "MyDataTable", element: <MyDataTable/> },
      { path: "Reviews", element: <Reviews/> },
      { path: "Rejster", element: <Rejster/> },
      { path: "about", element: <Info/> },
      { path: "FAQ", element: <FAQ/> },
      { path: "Detailseadentalclinic", element: <Detailseadentalclinic/> },
      { path: "*", element: <NotFound/> }
    ] 
  },
  { 
    path: "Dashboard", 
    element: <Dashboard/>,  // Dashboard layout for dashboard pages
    children: [
      { path: "", element: <LoginDashboard/> }, 
      { path: "homeDashboard", element: <HomeDashboard/> },
      { path: "slider", element: <Slider/> },
      { path: "About", element: <About/> },
    ]
  }
]);
export default routers;