import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../Context/Context';
import i18n from '../../i18n';
import { useTranslation } from 'react-i18next';
import { createTheme } from '@mui/material';

export default function Navbar() {
  const { t } = useTranslation();
  const x = useContext(UserContext);
  const [language, setLanguage] = useState(i18n.language || 'en');
  const [mymode, setMymode] = useState(() => localStorage.getItem('theme') || 'light');
  const [isScrolled, setIsScrolled] = useState(false);
  const [isHidden, setIsHidden] = useState(false);
  let lastScrollY = 0;

  const changeLanguage = (event) => {
    const selectedLanguage = event.target.value;
    i18n.changeLanguage(selectedLanguage);
    setLanguage(selectedLanguage);
    localStorage.setItem('language', selectedLanguage);
  };

  useEffect(() => {
    i18n.changeLanguage(language);
    document.body.dir = language === 'ar' ? 'rtl' : 'ltr';
  }, [language]);

  const toggleTheme = () => {
    setMymode((prevMode) => {
      const newMode = prevMode === 'light' ? 'dark' : 'light';
      localStorage.setItem('theme', newMode);
      return newMode;
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (currentScrollY > 80) {
        setIsScrolled(true);

        if (currentScrollY > lastScrollY) {
          setIsHidden(true); // إخفاء الـ Navbar عند التمرير للأسفل
        } else {
          setIsHidden(false); // إظهار الـ Navbar عند التمرير للأعلى
        }
      } else {
        setIsScrolled(false); // إعادة إظهار الـ Navbar عند العودة للأعلى
      }

      lastScrollY = currentScrollY;
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const darkTheme = createTheme({
    palette: {
      mode: mymode,
    },
  });

  let languag = localStorage.getItem("language");

  return (
    <div className={`bg-color d-none d-lg-block ${isScrolled ? 'navbar-scrolled' : ''} ${isHidden ? 'hide-navbar' : ''}`}>
      <div className='container'>
        <div className='row'>
          <div className="col-6 top-bar-social">
            <ul className="list-unstyled d-flex pt-1 pb-0 mb-0">
              <li className='mx-1'>
                <a href="" target="_blank">
                  <i className="fa-brands fa-twitter"></i>
                </a>
              </li>
              <li className='mx-1'>
                <a href="" target="_blank">
                  <i className="fa-brands fa-instagram"></i>
                </a>
              </li>
              <li className='mx-1'>
                <a href="" target="_blank">
                  <i className="fa-brands fa-youtube"></i>
                </a>
              </li>
              <li className='mx-1'>
                <a href="" target="_blank">
                  <i className="fa-brands fa-facebook-f"></i>
                </a>
              </li>
              <li className='mx-1 '>
                <a href="tel:+201551414900" target="_blank" className='text-decoration-none'>
                  +201551414900    <i className="fa fa-phone px-1"></i>
                </a>
              </li>
            </ul>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className={languag === "en" ? "text-end pt-1" : "text-start pt-1"}>
              <p className="text-white font-12 pt-1 fw-bold">{t("dentalclinic")}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
