import React, { useContext } from "react";
import { FirebaseContext } from "../Firbase/ContextFirbase";
import Slider from '../Slider/Slider'
import Navbar from "../Navbar/Navbar";
import MinNavbar from "../Navbar/MinNavbar";
import About from "../About/About";
import Services from "../Services/Services";
import OurServices from "../Services/OurServices";
import CounterServices from "../Services/CounterServices";
import ImgServices from "../Services/ImgServices";
import Testmonials from "../Testmonials/Testmonials";
import OurDoctors from "../OurDoctors/OurDoctors";
import BookAppointment from "../BookAppointment/BookAppointment";
import Footer from "../Footer/Footer";
import Fixedbody from "../Fixed/Fixedbody";
import { Helmet } from "react-helmet";

export default function Home() {
  // let x = useContext(FirebaseContext);
  // console.log("home" , x);



  return (
    <div className="container-fluid2">
      <Helmet>
    <title>Home Page</title>
  </Helmet>

      {/* <Navbar/> */}
     
      <Slider />
      <About />
      <Services />
      <OurServices />
      <CounterServices />
      <ImgServices />
      {/* <Testmonials/> */}
      <OurDoctors />
      <BookAppointment />
      <Fixedbody />
    </div>
  );
}
