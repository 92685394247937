import React, { useState, useEffect } from 'react';
import Loading from './Loading';
// import Loading from './Loading'; // تأكد من وجود مكون Loading

export default function Fixedbody() {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // محاكاة عملية تحميل
        const timer = setTimeout(() => {
            setLoading(false);
        }, 2000); // تغيير المدة حسب احتياجاتك

        return () => clearTimeout(timer);
    }, []);

    return (
        <>
            {loading && <Loading />}
            {!loading && (
                <>
                    <a href="https://wa.me/01126303082?text=I'd%20like%20to%20make%20an%20appointment" className=" d-none d-lg-block" target="_blank" rel="noopener noreferrer">
                        <div className='fixedwhite d-center '>
                            <i className="fa-brands fa-whatsapp"></i>
                        </div>
                    </a>

                    <a href="tel:+01126303082" className=" d-none d-lg-block">
                        <div className='fixedwhit d-center'>
                            <i class="fa-solid fa-phone"></i>
                        </div>
                    </a>


                    <div className='d-block d-lg-none'>
                        <div className='divResponseve'>
                            <div className='container'>
                                <div className='row'>



                                    <div className='col-6'>
                                        <div className='iconeresponsev d-center mt-4 mx-1 mx-auto'>
                                            <img src='https://cdn.pixabay.com/photo/2015/08/03/13/58/whatsapp-873316_1280.png' className='w-100' />
                                        </div>
                                        <a href="https://wa.me/01126303082?text=I'd%20like%20to%20make%20an%20appointment" className='text-white d-center mt-2'>WhatsApp Us</a>
                                    </div>
                                    <div className='col-6'>
                                        <a href='tel:%011558' className='text-white d-center mt-2 flex-column'>
                                            <div className='iconeresponsev d-center mt-4 mx-1 mx-auto'>
                                                <i class="fa fa-envelope" aria-hidden="true"></i>                                        </div>
                                            Email</a>
                                    </div>
                                    {/* <div className='col-6'>
                                    <a href='mailto:example@example.com'>
                                        <div className='iconeresponsev d-center mt-4 mx-1 '>
                                        <i class="fa fa-envelope" aria-hidden="true"></i>

                                        </div>
                                        <a href='mailto:example@example.com' className='text-white text-center  mt-2'>Email</a>
                                        </a> 
                                    </div> */}


                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
}
