import React from 'react'
import ImgDoctors from './ImgDoctors'
import { Link } from 'react-router-dom'
import Slider from 'react-slick';

export default function OurDoctors() {


    var settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };


    return <>

        <div className='bgsection' id='Doctors'>
            <div className='text-center'>
                <p className='fw-bold text-color'>Our Doctors</p>
                <h2 className='text-dark'>Meet Our Professional Doctors</h2>
            </div>
            <div className='container'>
                <div className='row mt-4 '>


                    <Slider {...settings}>
                        <div className=' '>
                            <div className='bg-white boxshado'>
                                <Link to="/Detailseadentalclinic">
                                    <div className='bg-white '>
                                        <div className='pt-3 mx-auto d-center flex-column  '>
                                            <img className='img-sercel ' src='https://eadentalclinic.com/wp-content/uploads/2024/06/team1.png' />
                                            <h4 className='fw-bold mt-3 text-dark '>DR:Amr Nazim</h4>
                                            <p className='text-dark'>Orthodontic   </p>
                                        </div>
                                    </div>
                                </Link>


                                <div className='text-center'>

                                    <a href="https://wa.me/01126303082?text=I'd%20like%20to%20make%20an%20appointment" class="btn btn-color mb-3 d-center" target="_blank">
                                        <i class="fab fa-whatsapp"></i> Make an Appointment
                                    </a>
                                </div>

                            </div>
                        </div>
                        <div className=' '>
                            <div className='bg-white boxshado'>
                                <Link to="/Detailseadentalclinic">
                                    <div className='bg-white '>
                                        <div className='pt-3 mx-auto d-center flex-column  '>
                                            <img className='img-sercel ' src='https://eadentalclinic.com/wp-content/uploads/2024/06/team1.png' />
                                            <h4 className='fw-bold mt-3 text-dark '>DR:Amr Nazim</h4>
                                            <p className='text-dark'>Orthodontic   </p>
                                        </div>
                                    </div>
                                </Link>


                                <div className='text-center'>

                                    <a href="https://wa.me/01126303082?text=I'd%20like%20to%20make%20an%20appointment" class="btn btn-color mb-3 d-center" target="_blank">
                                        <i class="fab fa-whatsapp"></i> Make an Appointment
                                    </a>
                                </div>

                            </div>
                        </div>
                    </Slider>




                    {/* <div className='col-lg-4 col-md-6 col-sm-12 '>
                        <div className='bg-white boxshado'>
                            <Link to="/Detailseadentalclinic">
                                <div className='bg-white '>
                                    <div className='pt-3 mx-auto text-center  '>
                                    <img className='img-sercel' src='https://eadentalclinic.com/wp-content/uploads/2024/06/team2.png' />
                                    <h4 className='fw-bold mt-3 text-dark '>DR:Amr Nazim</h4>
                                        <p className='text-dark'>Orthodontic   </p>
                                    </div>
                                </div>
                            </Link>


                            <div className='text-center'>

                                <a href="https://wa.me/01126303082?text=I'd%20like%20to%20make%20an%20appointment" class="btn btn-color mb-3 d-center" target="_blank">
                                    <i class="fab fa-whatsapp"></i> Make an Appointment
                                </a>
                            </div>

                        </div>
                    </div>
                    <div className='col-lg-4 col-md-6 col-sm-12 '>
                        <div className='bg-white boxshado'>
                            <Link to="/Detailseadentalclinic">
                                <div className='bg-white '>
                                    <div className='pt-3 mx-auto text-center  '>
                                    <img className='img-sercel' src='https://eadentalclinic.com/wp-content/uploads/2024/06/team3.png' />
                                    <h4 className='fw-bold mt-3 text-dark '>DR:Amr Nazim</h4>
                                        <p className='text-dark'>Orthodontic   </p>
                                    </div>
                                </div>
                            </Link>


                            <div className='text-center'>

                                <a href="https://wa.me/01126303082?text=I'd%20like%20to%20make%20an%20appointment" class="btn btn-color mb-3 d-center" target="_blank">
                                    <i class="fab fa-whatsapp"></i> Make an Appointment
                                </a>
                            </div>

                        </div>
                    </div> */}




                </div>
            </div>
            <ImgDoctors />
        </div>

    </>
}
