import React, { useState } from 'react';
import ShowReviews from './ShowReviews';

export default function Reviews() {
  const [rating, setRating] = useState(0);

  const handleStarClick = (star) => {
    setRating(star);
  };

  const Star = ({ star }) => (
    <span
      onClick={() => handleStarClick(star)}
      className={`star ${star <= rating ? 'filled' : ''}`}
    >
      ★
    </span>
  );

  return (
    <>
      <div className='container mt-4'>

        <div className='row'>
          <div className='col-lg-6 col-md-12'>
          <div className=''>
          <div className='addreviews'>
            <div className='pt-4'>
              <h5 className='text-center fw-bold'>Add Reviews</h5>
              <div className='mt-4 mx-3'>
                <div>
                  <label htmlFor='Name' className='mt-3'>Name:</label>
                  <input name='Name' id='Name' className='form-control mt-2' placeholder='Name' />
                </div>
                <div>
                  <label htmlFor='Email' className='mt-3'>E-mail:</label>
                  <input name='Email' id='Email' className='form-control mt-1' placeholder='E-mail' />
                </div>
                <div className='pb-4'>
                  <label htmlFor='Note' className='mt-3'>Note:</label>
                  <textarea id='Note' className='form-control' placeholder='Note' />
                </div>
                <div className='t'>
                  <label className='mt-3'>Rating:</label>
                  <div className='stars mt-2'>
                    {[1, 2, 3, 4, 5].map((star) => (
                      <Star key={star} star={star} />
                    ))}
                  </div>
                </div>
                <a href="#" className="btn btn-color text-white mb-4 mt-3">Send</a>
              </div>
            </div>
          </div>
        </div>
          </div>
          <div className='col-lg-6 col-md-12 d-none d-lg-block'>
            <img src='https://www.globitalmarketing.com/sg/wp-content/uploads/2021/07/df453we.png' className='w-100 reviweimg' />
          </div>
        </div>



      
      </div>

      <style jsx>{`
        .stars {
          font-size: 2rem;
          cursor: pointer;
        }
        .star {
          color: #d3d3d3;
        }
        .star.filled {
          color: #f39c12;
        }
      `}</style>
      <ShowReviews />

    </>
  );
}
