import React from 'react'

export default function Footer() {
  return <>
    <footer class="bg-color text-light mt-5 pb-3">
      <div class="container">
        <div class="row text-center">
          <div class="col-md-3 mt-5">
            <i class="fas fa-phone fa-2x text-color mb-2"></i>
            <h5>Emergency Email</h5>
            <p>+201551414900</p>
            <p>Call Us any time 24/7</p>
          </div>
          <div class="col-md-3 mt-5">
            <i class="fas fa-map-marker-alt fa-2x text-color mb-2"></i>
            <h5>OUR Address</h5>
            <p>Rehana Plaza tower (B) 8th floor,</p>
            <p>Zahraa Al Maadi, Maadi as Sarayat Al Gharbeyah,</p>
            <p>Tura, Cairo Governorate</p>
            <p>4064102</p>
          </div>
          <div class="col-md-3 mt-5">
            <i class="fas fa-phone-alt fa-2x text-color mb-2"></i>
            <h5>Book By Email</h5>
            <p>+201551414900</p>
          </div>
          <div class="col-md-3 mt-5">
            <i class="fas fa-clock fa-2x text-color mb-2"></i>
            <h5 >Working Hours</h5>
            <p>Saturday – Wednesday – Tuesday</p>
            <p>4PM - 10PM</p>
            <p>Thursday – Friday Closed</p>
          </div>
        </div>
      </div>

    </footer>
    <div class="text-center  bg-white ">
      <p className='p-2 m-0'>All Rights Are Reserved© EA DENTAL AESTHETICS 2024</p>
    </div>

  </>
}
