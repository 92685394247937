import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import logo from "../../../src/Img/Logo.png"
import { useLocation } from 'react-router-dom';

export default function MinNavbar() {
    const { t } = useTranslation();
    const [language, setLanguage] = useState(() => localStorage.getItem('language') || i18n.language || 'en');
    const [mymode, setMymode] = useState(() => localStorage.getItem('theme') || 'light');
    const [isScrolled, setIsScrolled] = useState(false);
    const changeLanguage = (event) => {
        const selectedLanguage = event.target.value;
        i18n.changeLanguage(selectedLanguage);
        setLanguage(selectedLanguage);
        localStorage.setItem('language', selectedLanguage);
    };
    let { pathname } = useLocation()
    // console.log(pathname);

    useEffect(() => {
        i18n.changeLanguage(language);
    }, [language]);

    // Function to handle scrolling behavior
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 80) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return ()=> {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className=''>
            <div className={`bg-white ${isScrolled ? 'navbar-scrolled' : ''}`}>
                <nav className="navbar navbar-expand-lg nav-h-100">
                    <div className="container">
                        <div className="navbar-brand">
                            <select value={language} onChange={changeLanguage} className='btnNav w-select '>
                                <option value="en">English</option>
                                <option value="ar">العربية</option>
                            </select>
                            <a href='https://wa.me/1234201056' className='text-decoration-none' target='_blank' rel='noopener noreferrer'>
                                <div className='d-block btnNav mt-1'>
                                    <div className='text-white mx-3'> {t("communication")}  </div>
                                </div>
                            </a>
                        </div>
                        <button
                            className="navbar-toggler text-dark"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent"
                            aria-controls="navbarSupportedContent"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <i className="fa-solid fa-bars fa-2x"></i>
                        </button>

                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav m-auto ">
                                <li className="nav-item mx-1">
                                    <a className={pathname == "/" ? "nav-link fw-bold infonav" : " nav-link fw-bold"} href="/">{t("home")}</a>
                                </li>
                                <li className="nav-item mx-1">
                                    <a className={pathname == "/about" ? "nav-link fw-bold infonav" : "  nav-link fw-bold"} href="/about">{t("about")}</a>
                                </li>
                                <li className="nav-item mx-1">
                                    <a className="nav-link fw-bold " href="#Services">  {t("services")}   </a>
                                </li>
                                <li className="nav-item mx-1">
                                    <a className="nav-link fw-bold " href="#Doctors">  {t("ourteam")}  </a>
                                </li>
                                <li className="nav-item mx-1">
                                    <a className={pathname == "/Reviews" ? "nav-link fw-bold infonav" : " nav-link fw-bold"} href="Reviews">   {t("reviews")} </a>
                                </li>
                                <li className="nav-item mx-1">
                                    <a className="nav-link fw-bold " href="#imgservise">    {t("gallery")} </a>
                                </li>


                                <li className="nav-item mx-1">
                                    <a className={pathname == "/FAQ" ? "nav-link fw-bold infonav" : " nav-link fw-bold"} href="/FAQ">  {t("faq")} </a>
                                </li>





                            </ul>
                            <form className="d-flex img-nav">
                                <a href='/'>

                                    <img src={logo} className='w-130 ' />
                                </a>
                            </form>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    );
}
