import React from 'react'
import { Helmet } from 'react-helmet'

export default function Detailseadentalclinic() {
    return <>



        <Helmet>
            <title>  Dr. Ahmad Deeb  </title>
        </Helmet>

        <div className='container-fluid'>

            <div>
                <img src='https://www.pearldentalclinics.com/assets/img/page-title/4.jpg' className='w-100' />
            </div>

            <div className='container mt-4'>

                <div className='row'>

                    <div className='col-lg-8 col-md-12'>
                        <div className='text-start mx-4'>
                            <h3 className='text-color'>Dr. Ahmad Deeb</h3>


                            <div className='mt-3'>
                                <p>
                                    Meet Dr. Ahmad Deeb, a distinguished dental professional who earned his Doctor of Dental Surgery degree with top honors from the International University of Ajman (UAE) in 2018. Driven by a passion for learning, he furthered his expertise by obtaining a Postgraduate Diploma in Implantology in 2019 and a Diploma in Dental Aesthetic Restorative procedures in 2020. This dedication has honed his skills in creating not only aesthetically pleasing but also functional smiles.
                                </p>
                                <p>
                                    With a rich international background, Dr. Ahmad boasts over five years of experience, specializing in dental cosmetics and implantology. His commitment to delivering exceptional results in a serene and stress-free environment is unwavering.
                                </p>
                                <p>
                                    To stay at the forefront of his field, Dr. Ahmad pursued a Clinical Implantology Dentistry Certificate and proudly joined the American Academy of Implantology (AAID). Actively involved in international scientific groups such as the International Team of Implantology and the Jordan Society of Oral Surgery, he balances clinical practice with continuous training courses.
                                </p>
                                <p>
                                    Beyond his dental expertise, Dr. Ahmad values quality time with his family, indulges in paddle sports, relishes sport bike rides, and actively engages in international campaigns. Through his volunteer efforts, he contributes to global dental awareness initiatives, showcasing his commitment to making a positive impact beyond the dental chair. Explore the world of dentistry with Dr. Ahmad Deeb, where skill, passion, and a personal touch come together for a brighter, healthier smile.
                                </p>
                            </div>
                            <div className='mt-5'>
                                <h3 className='text-color text-center'>Dental Expertise: Dr. Ahmad Deeb</h3>
                                <div className='about-video'>
                                    <div className='embed-responsive embed-responsive-16by9 w-100'>
                                        <iframe
                                            className='embed-responsive-item mt-4 w-100'
                                            src='https://www.youtube.com/embed/YoXbYkGRots'
                                            allowFullScreen
                                            title='About Video'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className='col-lg-4 col-md-12'>
                        <div className='boxshado'>
                            <div className='p-4'>
                                <img src='https://www.pearldentalclinics.com/uploads/1701251924.png' className='w-100' />
                            </div>

                            <div className='mt-3 text-center px-4 pb-3'>
                                <h3 className='text-color'>Dr. Ahmad Deeb</h3>
                                <p>Doctor of Dental Surgery</p>
                                <p>Specialist Implantologist & Cosmetic Dentist</p>



                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>

    </>
}
